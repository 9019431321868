import React from "react";

const SkeletonReport = () => {
    return (
        <div>
            <div role="status" class="flex flex-col ml-[25%] mr-[25%] space-y-4 divide-y divide-gray-200 rounded animate-pulse mb-10">
                <div class="flex">
                    <div>
                        <div className="flex flex-col">
                            <div className=" flex flex-row justify-between items-center">
                                <div className="w-60 h-6 bg-gray-400 rounded-lg mt-4 mb-5 animate-pulse shadow-inner shadow-gray-500"></div>
                                <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-36 shadow-inner shadow-gray-500"></div>
                            </div>
                            <div className="flex">
                                <div className="w-full mr-2">
                                    <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
                                        <div class="flex items-center w-full space-x-2">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[400px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-80 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[440px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[360px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-80 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>



                                    </div>

                                </div>
                                <div className="w-full mr-2">
                                    <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
                                        <div class="flex items-center w-full space-x-2">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[400px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-80 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[440px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[360px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-80 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>



                                    </div>

                                </div>
                                <div className="w-full">
                                    <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
                                        <div class="flex items-center w-full space-x-2">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[400px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[440px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[360px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                            <div className="flex flex-row justify-between items-end">
                                <div className="mt-10">
                                    <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-60 shadow-inner shadow-gray-500 mb-2"></div>
                                    <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-60 shadow-inner shadow-gray-500 mb-2"></div>
                                    <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-60 shadow-inner shadow-gray-500 mb-2"></div>
                                    <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-60 shadow-inner shadow-gray-500 mb-2"></div>
                                </div>
                                <div className="flex flex-row">
                                <div class="h-10 bg-gray-400 rounded dark:bg-gray-600 w-20 shadow-inner shadow-gray-500 mb-2 mr-5"></div>
                                <div class="h-10 bg-gray-400 rounded dark:bg-gray-600 w-20 shadow-inner shadow-gray-500 mb-2"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div role="status" class="flex flex-col ml-[25%] mr-[25%] p-4 space-y-4 divide-y divide-gray-200 rounded animate-pulse mb-10">
                <div class="flex">
                    <div>
                        <div className="flex flex-col">
                            <div className=" flex flex-row justify-between items-center">
                                <div className="w-60 h-6 bg-gray-400 rounded-lg mt-4 mb-5 animate-pulse shadow-inner shadow-gray-500"></div>
                                <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-36 shadow-inner shadow-gray-500"></div>
                            </div>
                            <div className="flex">
                                <div className="w-full mr-2">
                                    <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
                                        <div class="flex items-center w-full space-x-2">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[400px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-80 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[440px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[360px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-80 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>



                                    </div>

                                </div>
                                <div className="w-full mr-2">
                                    <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
                                        <div class="flex items-center w-full space-x-2">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[400px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-80 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[440px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[360px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-80 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>



                                    </div>

                                </div>
                                <div className="w-full">
                                    <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
                                        <div class="flex items-center w-full space-x-2">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[400px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[440px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[360px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                            <div className="flex flex-row justify-between items-end">
                                <div className="mt-10">
                                    <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-60 shadow-inner shadow-gray-500 mb-2"></div>
                                    <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-60 shadow-inner shadow-gray-500 mb-2"></div>
                                    <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-60 shadow-inner shadow-gray-500 mb-2"></div>
                                    <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-60 shadow-inner shadow-gray-500 mb-2"></div>
                                </div>
                                <div className="flex flex-row">
                                <div class="h-10 bg-gray-400 rounded dark:bg-gray-600 w-20 shadow-inner shadow-gray-500 mb-2 mr-5"></div>
                                <div class="h-10 bg-gray-400 rounded dark:bg-gray-600 w-20 shadow-inner shadow-gray-500 mb-2"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div role="status" class="flex flex-col ml-[25%] mr-[25%] p-4 space-y-4 divide-y divide-gray-200 rounded animate-pulse mb-10">
                <div class="flex">
                    <div>
                        <div className="flex flex-col">
                            <div className=" flex flex-row justify-between items-center">
                                <div className="w-60 h-6 bg-gray-400 rounded-lg mt-4 mb-5 animate-pulse shadow-inner shadow-gray-500"></div>
                                <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-36 shadow-inner shadow-gray-500"></div>
                            </div>
                            <div className="flex">
                                <div className="w-full mr-2">
                                    <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
                                        <div class="flex items-center w-full space-x-2">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[400px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-80 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[440px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[360px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-80 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>



                                    </div>

                                </div>
                                <div className="w-full mr-2">
                                    <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
                                        <div class="flex items-center w-full space-x-2">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[400px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-80 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[440px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[360px]">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-80 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>



                                    </div>

                                </div>
                                <div className="w-full">
                                    <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
                                        <div class="flex items-center w-full space-x-2">
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[400px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[480px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-32 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[440px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                        </div>
                                        <div class="flex items-center w-full space-x-2 max-w-[360px]">
                                        <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-24 shadow-inner shadow-gray-500"></div>
                                            <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-32 shadow-inner shadow-gray-500"></div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                            <div className="flex flex-row justify-between items-end">
                                <div className="mt-10">
                                    <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-60 shadow-inner shadow-gray-500 mb-2"></div>
                                    <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-60 shadow-inner shadow-gray-500 mb-2"></div>
                                    <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-60 shadow-inner shadow-gray-500 mb-2"></div>
                                    <div class="h-2.5 bg-gray-400 rounded-full dark:bg-gray-600 w-60 shadow-inner shadow-gray-500 mb-2"></div>
                                </div>
                                <div className="flex flex-row">
                                <div class="h-10 bg-gray-400 rounded dark:bg-gray-600 w-20 shadow-inner shadow-gray-500 mb-2 mr-5"></div>
                                <div class="h-10 bg-gray-400 rounded dark:bg-gray-600 w-20 shadow-inner shadow-gray-500 mb-2"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>






    );
};

export default SkeletonReport;